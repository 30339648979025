import { Component, OnInit } from '@angular/core';
import { MyconnserviceService } from '../../services/myconnservice.service';
import { Router, NavigationExtras } from '@angular/router';
import { ModalController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-student-update-modal',
  templateUrl: './student-update-modal.page.html',
  styleUrls: ['./student-update-modal.page.scss'],
})
export class StudentUpdateModalPage implements OnInit {
  student:any;
  apiResponse:any;

  userfullname:any;
  accountid:any;

  add_update_url:string;
  frm_btn_txt:string;
  frm_btn_color:string;
  update_id:number = 0;
  
  showform:boolean = false;

  branches:any;
  qualifications:any;
  courses:any;
  students:any;

  sel_branch:any;
  sel_quali:any;
  studname:any;
  mobile:any;
  wano:any;
  studemail:any;
  schoolname:any;
  mothername:any;
  fatherprofession:any;
  mothertoung:any;
  address:any;
  desc:any;
  cou_start_date;
  regdate:String;
  dob:any;
  
  constructor(public myconnserviceService:MyconnserviceService,private router: Router, private modalCtrl: ModalController) { }

  ngOnInit() {
    this.studname = this.student['StudFullName'];
    this.mobile = this.student['StudMobile'];
    this.wano = this.student['StudWA'];
    this.studemail = this.student['StudEmail'];
    this.schoolname = this.student['StudSchoolName'];
    this.mothername = this.student['StudMotherName'];
    this.fatherprofession = this.student['StudFatherProffesion'];
    this.mothertoung = this.student['StudMotherToung']; 
    this.address = this.student['StudAddress'];
    this.regdate = this.student['StudDOR'];
    this.dob = this.student['StudDOB'];
    this.desc = this.student['StudDesc'];

    var postData = {};
    var response = this.myconnserviceService.sendPostRequest('get-addstud-data',postData).subscribe(
      (responseData)=>{
        this.apiResponse = responseData;
          if(this.apiResponse['status'] == 1)
          {
            this.branches = this.apiResponse['data']['branches'];    
            this.qualifications = this.apiResponse['data']['qualifications'];    
          }else{
            alert('No Data Found! Try Again');
          }
          // this.ionLoaderService.dismissLoader();        
      });

      this.sel_branch = this.student['StudBranch'];
      this.sel_quali = this.student['StudQualification'];
  }

  update_student_data(){
    var postData = {
      StudQualification	 : this.sel_quali,
      StudBranch	 : this.sel_branch,
      StudFullName	 : this.studname,
      StudMobile	 : this.mobile,
      StudWA	 : this.wano,
      StudEmail : this.studemail,
      StudSchoolName : this.schoolname,
      StudMotherName : this.mothername,
      StudFatherProffesion	: this.fatherprofession,
      StudMotherToung : this.mothertoung,      
      StudDOB	 : this.dob,
      StudDOR	 : this.regdate,
      StudAddress	 : this.address,
      StudDesc	 : this.desc,      
      updateid	 : this.student['idstudents'],      
    };

    var response = this.myconnserviceService.sendPostRequest('student-add-update',postData).subscribe(
      (responseData)=>{
        this.apiResponse = responseData;
          if(this.apiResponse['status'] == 1)
          {
            var stud_id = this.apiResponse['data']['idstud'];
            // this.modalCtrl.dismiss();
            this.router.navigate(['/student-profile', stud_id]);
            this.myconnserviceService.showAlert_success('Student Data UpdatedSuccessfully.');
          }else{
            alert('No Data Found! Try Again');
          }
          // this.ionLoaderService.dismissLoader();        
      });
  }
  
  close_Modal(){
     this.modalCtrl.dismiss();
  }

}
