import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { MyconnserviceService } from '../../services/myconnservice.service';
import { LoadingService } from '../../services/loading.service';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-order-detail-modal',
  templateUrl: './order-detail-modal.page.html',
  styleUrls: ['./order-detail-modal.page.scss'],
})
export class OrderDetailModalPage implements OnInit {
  order_id;
  apiResponse:any;
  order:any;
  orderitems:any;
  accept_note:any;
  sel_status:any;
  status_color:any;

  constructor(private myLoader: LoadingService,public myconnserviceService:MyconnserviceService, private modalCtrl: ModalController) { }

  ngOnInit() {
    this.get_order_details(this.order_id);
    // console.log('Id : '+ this.order_id);
  }

  get_order_details(orderid){ 

    this.myLoader.present();

    var postData = {
      'orderid':this.order_id,
    };
    var response = this.myconnserviceService.sendPostRequest('get-order-details',postData).subscribe(
      (responseData)=>{
        this.apiResponse = responseData;
        // console.log('Data : '  + this.apiResponse);  
        if(this.apiResponse['status'] == 1)
          {  
            this.order = this.apiResponse['data']['orders'];
            this.orderitems = this.apiResponse['data']['order_items'];
            this.status_color = this.apiResponse['data']['orders'].OStatus_Color;

            this.myLoader.dismiss();
          }else{
            this.myLoader.dismiss();
          }
      });
  }

  order_received(){
    this.myLoader.present();

    var postData = {
      'orderid':this.order_id,
      'accept_note' : this.accept_note,
      'sel_status' : this.sel_status
    };
    var response = this.myconnserviceService.sendPostRequest('order-received-by-center',postData).subscribe(
      (responseData)=>{
        this.apiResponse = responseData;
        // console.log('Data : '  + this.apiResponse);  
        if(this.apiResponse['status'] == 1)
          {  
            this.order = this.apiResponse['data']['orders'];
            this.status_color = this.apiResponse['data']['orders'].OStatus_Color;
            console.log('color ' + this.status_color);
            this.myLoader.dismiss();
          }else{
            this.myLoader.dismiss();
          }
      });
  }

  close_Modal(){
    this.modalCtrl.dismiss();
 }

}
